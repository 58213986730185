import { Directive, ElementRef, HostListener, input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appDynamicTooltip]',
  standalone: true,
  providers: [
    MatTooltip
  ],
})

export class DynamicTooltipDirective implements OnInit, OnChanges {

  tooltipMessage = input.required<string>();
  dynamic = input();

  constructor(
    private tooltip: MatTooltip,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.tooltip.message = this.tooltipMessage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tooltipMessage']) {
      this.tooltip.message = this.tooltipMessage();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.elementRef.nativeElement.scrollWidth > this.elementRef.nativeElement.offsetWidth || !this.dynamic()) {
      this.tooltip.show();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.tooltip.hide();
  }

}